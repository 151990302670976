import { createSlice } from "@reduxjs/toolkit";

const data =
  typeof window !== "undefined" && localStorage.getItem("quiz")
    ? JSON.parse(localStorage.getItem("quiz"))
    : [];

const quizSlice = createSlice({
  name: "quiz",
  initialState: data,
  reducers: {
    addAnswer: (state, { payload }) => {
      const answerInQuiz = state.find((item) => item.id === payload.id);
      if (answerInQuiz) {
        state.splice(state.indexOf(answerInQuiz), 1, payload);
      } else {
        state.push({ ...payload });
      }
      localStorage.setItem("quiz", JSON.stringify(state));
      return state;
    },

    resetQuiz: (state, _) => {
      state = [];
      localStorage.setItem("quiz", JSON.stringify(state));
      return state;
    },
  },
});

export const quizReducer = quizSlice.reducer;

export const { addAnswer, resetQuiz } = quizSlice.actions;

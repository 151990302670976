import { createSlice } from "@reduxjs/toolkit";

const data =
  typeof window !== "undefined" && localStorage.getItem("counter")
    ? JSON.parse(localStorage.getItem("counter"))
    : { value: 0 };

const counterSlice = createSlice({
  name: "counter",
  initialState: data,
  reducers: {
    incrementCounter(state) {
      state.value++;
      localStorage.setItem("counter", JSON.stringify(state));
    },
    decrementCounter(state) {
      state.value--;
      localStorage.setItem("counter", JSON.stringify(state));
    },
    resetCounter: (state, _) => {
      state = { value: 0 };
      localStorage.setItem("counter", JSON.stringify(state));
      return state;
    },
  },
});

export const counterReducer = counterSlice.reducer;

export const { incrementCounter, decrementCounter, resetCounter } =
  counterSlice.actions;

import { createSlice } from "@reduxjs/toolkit";

const data =
  typeof window !== "undefined" && localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : {};

const userSlice = createSlice({
  name: "user",
  initialState: data,
  reducers: {
    loginUser: (state, { payload }) => {
      state = { ...state, ...payload };
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },

    logoutUser: (state, _) => {
      state = [];
      localStorage.setItem("user", JSON.stringify(state));
      return state;
    },
  },
});

export const userReducer = userSlice.reducer;

export const { loginUser, logoutUser } = userSlice.actions;

import { useEffect } from "react"
import App from "next/app"
import Head from "next/head"
import ErrorPage from "next/error"
import { useRouter } from "next/router"
import { DefaultSeo } from "next-seo"
import { getStrapiMedia } from "utils/media"
import { getGlobalData } from "utils/api"
import TagManager from "react-gtm-module"
import { appWithTranslation } from "next-i18next"
import NextNProgress from "nextjs-progressbar"
import "@/styles/index.css"

import store from "../redux/store"
import { Provider } from "react-redux"

const MyApp = ({ Component, pageProps }) => {
  const { locale, defaultLocale } = useRouter()

  // Extract the data we need
  const { global } = pageProps
  if (global == null) {
    return <ErrorPage statusCode={404} />
  }

  const { metadata } = global

  useEffect(() => {
    TagManager.initialize({ gtmId: metadata.gtm })
  }, [metadata.gtm])

  return (
    <>
      {/* Favicon */}
      <Head>
        <link rel="shortcut icon" href={getStrapiMedia(global.favicon.url)} />
      </Head>
      {/* Global site metadata */}
      <DefaultSeo
        titleTemplate={`%s | ${global.metaTitleSuffix}`}
        title="Page"
        description={metadata.metaDescription}
        canonical={
          locale === defaultLocale
            ? `${process.env.NEXT_PUBLIC_URL}/`
            : `${process.env.NEXT_PUBLIC_URL}/${locale}/`
        }
        languageAlternates={[
          { hrefLang: "x-default", href: `${process.env.NEXT_PUBLIC_URL}/` },
          { hrefLang: "uk-UA", href: `${process.env.NEXT_PUBLIC_URL}/` },
          { hrefLang: "ru-UA", href: `${process.env.NEXT_PUBLIC_URL}/ru/` },
        ]}
        openGraph={{
          type: "website",
          url: `${process.env.NEXT_PUBLIC_URL}/`,
          title: `${global.metaTitleSuffix}`,
          description: metadata.metaDescription,
          images: Object.values(metadata.shareImage.formats).map((image) => {
            return {
              url: getStrapiMedia(image.url),
              width: image.width,
              height: image.height,
            }
          }),
        }}
        twitter={{
          cardType: metadata.twitterCardType,
          handle: metadata.twitterUsername,
        }}
      />
      {/* Display the content */}
      <Provider store={store}>
        <NextNProgress
          color="#FF6B0A"
          startPosition={0.3}
          stopDelayMs={200}
          height={3}
          showOnShallow={true}
        />
        <Component {...pageProps} />
      </Provider>
    </>
  )
}

// getInitialProps disables automatic static optimization for pages that don't
// have getStaticProps. So [[...slug]] pages still get SSG.
// Hopefully we can replace this with getStaticProps once this issue is fixed:
// https://github.com/vercel/next.js/discussions/10949
MyApp.getInitialProps = async (appContext) => {
  // Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext)
  const globalLocale = await getGlobalData(appContext.router.locale)

  return {
    ...appProps,
    pageProps: {
      global: globalLocale,
    },
  }
}

export default appWithTranslation(MyApp)

import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { userReducer } from "./userSlice";
import { quizReducer } from "./quizSlice";
import { counterReducer } from "./counterSlice";

const store = configureStore({
  reducer: {
    user: userReducer,
    quiz: combineReducers({
      counter: counterReducer,
      results: quizReducer,
    }),
  },
});

export default store;
